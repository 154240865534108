import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { qs, qsa, fetchJSON, setState } from "../utils/lib";

const ProductSidebar = (props) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetch(`/api/products/`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (e) => {
    const goHere = products.find((el) => el.title === e.target.value);
    window.location.href = goHere.slug.toLowerCase();
  };

  return (
    <div className="col product_page_select">
      <div className="jumbotron_inner grid no_margin">
        <p className="breadcrumbs c-dark">
          <a className="c-dark h8" href="/collection">
            {props.t.bread_collection}
          </a>{" "}
          / <span className="bold">{props.t.bread_choose}</span>
        </p>
      </div>
      <div className="product_page_select_menu">
       
        <div className="arrow">
          <img src="/images/arrow-select.svg" alt="" />
        </div>
        {products.length !== 0 && props.singleProduct && (
          <select
            onChange={(e) => handleChange(e, products)}
            defaultValue={props.singleProduct.title}
            className="h6 medium"
            name={props.singleProduct.title}
          >
            {products.map((p) => {
              return (
                <option value={p.title} key={p.id}>
                  {p.title}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className="text-padding-bottom-1">
        <p className="c-dark medium h6 text-padding-bottom-1">{props.t.side_panel}</p>
        <p className="c-dark h7 text-padding-bottom-1 capitalize">
          {props.singleProduct && props.singleProduct.side_panel}
        </p>
      </div>
      { props.singleProduct && props.singleProduct.slab && (
        <div className="text-padding-bottom-1">
          <p className="medium c-dark h6 text-padding-bottom-1">{props.t.slab}</p>
          <p className="c-dark h7 text-padding-bottom-1 capitalize">
            {props.singleProduct && props.singleProduct.slab}
          </p>
        </div>
      )}
      { props.singleProduct && props.singleProduct.drip_tray && (
        <div className="text-padding-bottom-1">
            <p className="medium c-dark h6 text-padding-bottom-1">{props.t.drip_tray}</p>
            <p className="c-dark h7 text-padding-bottom-1 capitalize">
            {props.singleProduct && props.singleProduct.drip_tray}
            </p>
        </div>
      )}
      <a
        href={`mailto:info@stone-espresso.com?subject=${"Info about "}${
          props.singleProduct && props.singleProduct.title
        }`}
      >
        <button className="h8 button wr upcase medium text-center bg-accent c-light">
          {props.t.request_cta}
        </button>
      </a>
    </div>
  );
};

export default ProductSidebar;
