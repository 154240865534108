import React, { useState, useEffect } from "react";
import ProductGallery from "./product_gallery";
import ProductSidebar from "./product_sidebar";

const ProductPage = (props) => {
  const [singleProduct, setSingleProduct] = useState();
  const slug = props.slug;
  const translations = JSON.parse(props.translations)

  useEffect(() => {
    fetch(`/api/product/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setSingleProduct(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <React.StrictMode>
      <section className="product_page grid no_margin bg-productgrey">
        <ProductGallery 
          slug={slug}
          t={translations} />
        <ProductSidebar 
          singleProduct={singleProduct}
          t={translations} />
      </section>
    </React.StrictMode>
  );
};

export default ProductPage;
