import {qs,qsa} from '../utils/lib'
const ProductFilter = () => {
    const filters = qsa('button[data-filter]')
    const products = qsa('.js-product-col')
    let active_filter = ""
    let active_btn = ""
    filters.forEach(btn => {
        btn.addEventListener('click', () => {
            active_filter = btn.dataset.filter
            active_btn = btn
            toggleButtons(btn)
            toggleProduct()
        })
    })
    const toggleProduct = () => {
        products.forEach(product => {
            product.classList.remove('hidden')
            if (product.dataset.collection == active_filter) {
                product.classList.remove('hidden')
            } else {
                product.classList.add('hidden')
            }   
        })
    }
    const toggleButtons = () => { 
        filters.forEach(btn => {
            if (active_btn == btn) {
                btn.classList.add('c-accent')
            } else {
                btn.classList.remove('c-accent')
            }        
        })
    }
}
export default ProductFilter