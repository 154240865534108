import { qs, qsa } from "../utils/lib";
import gsap from "gsap/all";

export default class Accordion {
    constructor(el) {
        this.accordion = el;
        this.titles = qsa('button', this.accordion)
        this.els = qsa('.accordion',el)
        this.filter = ""
        this.duration = .4
        this.ease_in = "ease-in"
        this.ease_out = "ease-out"
    }
    init() {
        this.titles.forEach(t => {
            t.addEventListener('click', () => {
                if (t.dataset.expanded == "false") {
                    this.openTab(t)
                } else {
                    this.closeTab(t)
                }
                
            })
        })
    }
    openTab(summary) {
        
        summary.setAttribute('data-expanded','true')
        const wrapper = qs('.accordion_content',summary.parentNode)
        const els = qsa('.accordion_content_el',summary.parentNode)
        const arrow = summary.querySelector('img')
        gsap.to(arrow,{
            rotate: 270,
            duration: 0.1
        })       
        const tl = gsap.timeline()
        wrapper.classList.add('open')  
        tl.to(wrapper,{
            autoAlpha: 1,
            height: "auto",
            duration: this.duration
        })        
        .to(els,{
            opacity: 1,
            duration: this.duration,
            ease: this.ease_in,
            delay: 0.2,
            stagger: 0.1
        }) 
    }
    closeTab(summary) {
        summary.setAttribute('data-expanded','false')
        const wrapper = qs('.accordion_content',summary.parentNode)
        const arrow = summary.querySelector('img') 
        gsap.to(wrapper,{
            autoAlpha: 0,
            height: 0,
            duration: this.duration,
            onComplete: () => wrapper.classList.remove('open')   
        }) 
        gsap.to(arrow,{
            rotate: 90,
            duration: 0.1
        })                                
    }
}