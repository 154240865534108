import gsap from "gsap";
import anime from "animejs/lib/anime.es.js";
import { qs, qsa } from "../utils/lib";
import { inOpacity } from "./animations";

export function Counter(maxVal, duration) {
  let count = { val: 0 };
  let NewVal = maxVal;
  let countElement = qs(".counterElement");

  inOpacity(countElement, 0, 1, 1);

  gsap.to(count, duration, {
    val: NewVal,
    roundProps: "val",
    onUpdate: function () {
      countElement.innerHTML = count.val + "’";
    },
  });
}

export function CircleSvg() {
  const circle = qs(".red_circ");
  anime({
    targets: circle,
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: "easeInOutSine",
    duration: 1500,
  });
}

export function Sizes() {
  let sizeElements = qsa(".sizeElement");
  for (let i = 0; i < sizeElements.length; i++) {
    const element = sizeElements[i].innerHTML;

    let count = { val: 0 };
    let NewVal = element;

    inOpacity(sizeElements, 0, 1, 1);

    gsap.to(count, 1.5, {
      val: NewVal,

      onUpdate: function () {
        sizeElements[i].innerHTML = count.val.toFixed(0);
        sizeElements[sizeElements.length - 1].innerHTML = count.val.toFixed(1);
      },
    });
  }
}
