import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { qs, qsa } from "../utils/lib";
gsap.config({ nullTargetWarn: false });

export function inOpacity(el, fromOpacity, toOpacity, time) {
  gsap.fromTo(
    el,
    {
      opacity: fromOpacity,
    },
    {
      opacity: toOpacity,
      duration: time,
    }
  );
}

export function scrollHero() {
  if (innerWidth >= 640)
    gsap.set(".image_cont img", {
      scale: 1.2,
      duration: 0,
    });

  gsap.to(".image_cont img", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom bottom",
      toggleActions: "restart pause reverse pause",
      scrub: true,
    },
    scale: 1,
    duration: 0.7,
    ease: "sine.inOut",
  });
}

export function ProductOpacity() {
  qsa(".p_card").forEach((el) => {
    el.addEventListener("mouseenter", (evt) => {
      gsap.to(el, {
        opacity: 0.5,
        ease: "cubic-bezier(0.65,0.05,0.36,1)",
      });
    });

    el.addEventListener("mouseleave", (evt) => {
      gsap.to(el, {
        opacity: 1,
        ease: "cubic-bezier(0.65,0.05,0.36,1)",
      });
    });
  });
}
