import { qs, qsa } from "../utils/lib";

export const colorLabel = () => {
  let labelsList = qsa(".radio label");

  labelsList.forEach((el, i) => {
    el.addEventListener("click", (evt) => {
      for (let i = 0; i < labelsList.length; i++) {
        labelsList[i].classList.remove("active");
        labelsList[i].style.marginTop = -10;
      }

      el.classList.add("active");
    });
  });
};
