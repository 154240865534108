import { qs, qsa } from "../utils/lib";
import gsap from "gsap";

export default class hostCarousel {
  constructor(el) {
    this.slider = el;
    this.tl = gsap.timeline();
    this.dots = qsa(".dot-cont", el);
    this.images = qsa(".slider-carousel-track img", el);
    this.rightImages = qsa(".hostcont img");
    this.carouselTrack = qs(".slider-carousel-track");
    this.imageIsChanged = false;
    this.active_dot = 0;
  }

  getActiveDot(i) {
    this.active_dot = i;
    this.dots.forEach((dot, i) => {
      dot.classList.remove("active");
      if (this.active_dot == i) {
        dot.classList.add("active");
      }
    });
  }

  init() {
    this.rightImages.forEach((img) => {
      gsap.to(img, {
        opacity: 0,
      });
    });

    this.dots.forEach((el, i) => {
      el.addEventListener("click", (evt) => {
        this.getActiveDot(i);
        if (this.imageIsChanged) {
          return;
        } else {
          this.imageIsChanged = true;

          this.tl.to(this.carouselTrack, {
            x: -this.images[0].clientWidth * i,
            duration: 0.7,
            ease: "Power1.easeInOut",
          });

          this.setRightImgs(i);
          this.imageIsChanged = false;
        }
      });
    });
  }

  setRightImgs(active_el) {
    this.rightImages.forEach((img, i) => {
      if (i === active_el) {
        gsap.to(img, {
          opacity: 1,
          duration: 0.5,
          ease: "Sine.easeOut",
        });
      } else {
        gsap.to(img, {
          opacity: 0
        });
      }
    });
  }

  // setRightImgs(i) {
  //   this.rightImages.forEach((img) => {
  //     gsap.to(img, {
  //       opacity: 0,
  //     });
  //   });

  //   gsap.to(this.rightImages[i], {
  //     opacity: 1,
  //     duration: 0.5,
  //   });
  // }
}
