import gsap from "gsap";
import { qs, qsa } from "../utils/lib";
import { Counter, CircleSvg, Sizes } from "./counterAnimations";

export default class IntersectionObs {
  constructor() {
    this.target = Array.from(document.querySelectorAll("[data-animation]"));
    this.options = {
      rootMargin: "0px 0px 0px 0px",
    };
  }

  init() {
    this.setTargets();
    this.initObserver();
  }

  setTargets() {
    this.target.forEach((el) => {
      if (el.dataset.animation === "text") {
        gsap.set(el, {
          opacity: 0,
          y: 20,
          duration: 0,
        });
      }
      if (el.dataset.animation === "img") {
        gsap.set(el, {
          opacity: 0,
          scale: 1.01,
          duration: 0,
        });
      }
    });
  }

  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.dataset.animation == "counter") {
          // console.log("observed");
          Counter(10, 1.5);
          CircleSvg();
        }

        if (entry.target.dataset.animation == "sizes") {
          Sizes();
        }

        if (entry.target.dataset.animation === "text") {
          gsap.to(entry.target, {
            opacity: 1,
            y: 0,
            duration: 1,
          });
        }

        if (entry.target.dataset.animation === "img") {
          entry.target.addEventListener("load", () => {
            gsap.to(entry.target, {
              opacity: 1,
              ease: "Expo.out",
              scale: 1,
              duration: 1,
            });
          });
          entry.target.setAttribute(
            "src",
            entry.target.getAttribute("alt-src")
          );
        }

        this.observer.unobserve(entry.target);
      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(
      this.animation.bind(this),
      this.options
    );
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }
}
