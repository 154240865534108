import Siema from "siema";
const ProductSlides = (el) => {
    const selector = el.querySelector('.js-product-carousel-init')
    const prev = el.querySelector('.product_carousel_prev')
    const next = el.querySelector('.product_carousel_next')
    const dots = el.querySelectorAll('.dot')
    let active = 0
    const slider = new Siema({
      selector: selector,
      duration: 200,
      easing: 'ease-out',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      multipleDrag: true,
      threshold: 20,
      loop: true,
      rtl: false,
      onInit: () => {},
      onChange: () => toggleActiveDots(),      
    })
    prev.addEventListener('click', () => slider.prev())
    next.addEventListener('click', () => slider.next())
    dots.forEach((dot,index) => dot.addEventListener('click', () => {
        slider.goTo(index)
        toggleActiveDots()
    }))


    const toggleActiveDots = () => {
        dots.forEach((dot, index) => {            
            if (index == slider.currentSlide) {
                dot.classList.add('active') 
            } else {
                dot.classList.remove('active') 
            }
        })
    }
}
export default ProductSlides