import { qs, qsa } from "../utils/lib";

export default function () {
  const buttons_container = qs(".specifications_select");
  const buttons = qsa(".specifications_select button");
  const specificationsMM = qs(".specifications_mm");
  const specificationsIN = qs(".specifications_in");

  buttons_container && buttons_container.addEventListener("click", (evt) => {
    if (buttons[0].classList.contains("active")) {
      buttons[0].classList.remove("active");
      buttons[1].classList.add("active");
      specificationsMM.classList.add("display-none");
      specificationsIN.classList.remove("display-none");
    } else {
      buttons[0].classList.add("active");
      buttons[1].classList.remove("active");
      specificationsMM.classList.remove("display-none");
      specificationsIN.classList.add("display-none");
    }
  });
}
