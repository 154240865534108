import "../../scss/application.scss";

import IntersectionObs from "../components/intersection_obs";
import { qs, qsa } from "../utils/lib";
import Header from "../components/header";
import Specifications from "../components/specifications";
import React from "react";
import ReactDOM from "react-dom";
import ProductPage from "../react_components/product_page";
import HostCarousel from "../components/hostCarousel";
import { colorLabel } from "../components/form";
import Accordion from "../components/accordion";
import { ProductOpacity, scrollHero } from "../components/animations";
import ProductFilter from '../components/product-filter'

import ProductSlides from "../components/product-slides";

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 768) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);    
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });    
  }
  const inter = new IntersectionObs();
  inter.init();

  Header();
  scrollHero();
  Specifications();
  colorLabel();
  ProductOpacity();


  const p_page = document.querySelector('#product_page')
  if (p_page) {
    
    ProductFilter()
  }
  if (qsa(".js-accordion")) {
    const accordions = qsa(".js-accordion")
    accordions.forEach((acc) => {
      const _acc = new Accordion(acc)
      _acc.init()
    })
  }

  const product_carousel = qs('.js-product-carousel')
  if (product_carousel) {
  ProductSlides(product_carousel)
  }

  const hostSlider = qs(".host_slider");
  if (hostSlider) {
    const hostComponent = new HostCarousel(hostSlider);
    hostComponent.init();
  }

  /*    REACT COMPONENTS    */
  const product_configurator = qs(".jsx-product-page");
  product_configurator &&
    ReactDOM.render(
      <ProductPage 
        slug={product_configurator.dataset.slug}
        translations={product_configurator.dataset.translations} />, product_configurator);
});
